<template>
  <v-row align="center" justify="center" no-gutters class="overflow-x-auto">
    <vue-headful
      title="Automotriz Petersen - Mision y Vision"
      description="En Petersen nos comprometemos a hacer nuestros los intereses del cliente. Sabemos lo importante y significativo que puede ser la compra o venta de un vehículo, es por eso que nuestra gestión se centra en asesorías honestas, confiables y transparentes hacia nuestros clientes. Valoramos y cultivamos un ambiente laboral en el que la colaboración, la integridad y el desarrollo personal impulsan el éxito colectivo basados en la confianza y el respeto."
    />
    <v-col
      cols="12"
      :xs="12"
      :sm="12"
      :md="6"
      :lg="6"
      :xl="4"
      :class="!$vuetify.breakpoint.smAndDown ? 'mt-12 mb-2' : 'mb-2'"
    >
      <v-card class="my-3">
        <v-card-title>Misión</v-card-title>
        <v-card-text>
          En Petersen nos comprometemos a hacer nuestros los intereses del
          cliente. Sabemos lo importante y significativo que puede ser la compra
          o venta de un vehículo, es por eso que nuestra gestión se centra en
          asesorías honestas, confiables y transparentes hacia nuestros
          clientes. Valoramos y cultivamos un ambiente laboral en el que la
          colaboración, la integridad y el desarrollo personal impulsan el éxito
          colectivo basados en la confianza y el respeto.
        </v-card-text>
      </v-card>
      <v-card :class="!$vuetify.breakpoint.smAndDown ? 'mt-12' : ''">
        <v-card-title>Visión</v-card-title>
        <v-card-text>
          Aspiramos a ser un referente, en la generación de relaciones
          duraderas, basadas en la confianza y la satisfacción, donde cada
          interacción refleje nuestro compromiso genuino con el bienestar y
          satisfacción tanto de nuestros clientes como de nuestros
          colaboradores.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style></style>
